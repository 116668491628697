import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";

import Register from "../views/Register.vue";

import ResetPassword from "../views/resetPassword.vue";
import Cookies from "js-cookie";

// Listen for the 'beforeunload' event
window.addEventListener("beforeunload", (event) => {
  // Check if the tab is being closed
  if (event.clientX < 0 && event.clientY < 0) {
    // Clear token and user information when the tab is closed
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
});
const authGuard = (to, from, next) => {
  const isAuthenticated = localStorage.getItem("token") !== null;
  if (
    isAuthenticated &&
    (to.path === "/login" || to.path === "/" || to.path === "/forget")
  ) {
    next("/dashboard");
  } else if (isAuthenticated || to.path === "/login" || to.path === "/forget") {
    next();
  } else {
    next("/login");
  }
};

const routes = [
  { path: "/", name: "login", component: Login },
  { path: "/register", name: "Register", component: Register },
  { path: "/login", component: Login },
  {
    path: "/pre-order-agency/:id",
    name: "View Agency",
    component: () => import("../views/AgencyPreorder.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/pre-order-account/:id",
    name: "View Account",
    component: () => import("../views/AccountPreorder.vue"),
    beforeEnter: authGuard,
  },

  {
    path: "/forget",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    component: () => import("../views/Warehouse.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/pre-order",
    name: "Pre Order",
    component: () => import("../views/PreOrder.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/shipped-transponders",
    name: "Shipped Transponders",
    component: () => import("../views/ShippedTransponders.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/clients",
    name: "Clients",
    component: () => import("../views/Clients.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/agencies",
    name: "Agencies",
    component: () => import("../views/Agencies.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/threshold",
    name: "Threshold",
    component: () => import("../views/Threshold.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/tag-types",
    name: "Tag Types",
    component: () => import("../views/TagTypes.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/client-accounts",
    name: "Client Accounts",
    component: () => import("../views/ClientAccounts.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/transponder-type",
    name: "Transponder Type",
    component: () => import("../views/TransponderType.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/transponder-status",
    name: "Transponder Status",
    component: () => import("../views/TransponderStatus.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/inventory-status",
    name: "Inventory Status",
    component: () => import("../views/InventoryStatus.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/location",
    name: "Client Locations",
    component: () => import("../views/Location.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/departments/:id",
    name: "Departments",
    component: () => import("../views/Departments.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/transponders/:id",
    name: "Transponders",
    component: () => import("../views/Transponders.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/my-client-open-orders",
    name: "My Client Open orders",
    component: () => import("../views/MyClientOpenOrders.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/my-ready-for-shipping-orders",
    name: "My Open Orders Ready for Shipping",
    component: () => import("../views/MyReadyForShippingOrders.vue"),
    beforeEnter: authGuard,
  },

  {
    path: "/client-open-orders",
    name: "All Client Open orders",
    component: () => import("../views/AllClientOpenOrders.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/my-carrier-open-orders",
    name: "My carrier Open orders",
    component: () => import("../views/MyCarrierOpenOrders.vue"),
    beforeEnter: authGuard,
  },

  {
    path: "/carrier-open-orders",
    name: "All Carrier Open orders",
    component: () => import("../views/AllCarrierOpenOrders.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/shipping-addresses",
    name: "Shiping Adresses",
    component: () => import("../views/ShippingAddresses.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/open-order-assignments",
    name: "Open Order Assignments",
    component: () => import("../views/OpenOrderAssignments.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: authGuard,
    meta: { title: "Dashboard" },
  },

  {
    path: "/order-detail",
    name: "Open Order Details",
    component: () => import("../views/OrderDetails.vue"),
    beforeEnter: authGuard,
    meta: { title: "Open Order Details" },
  },

  {
    path: "/receive-transponder",
    name: "Receive Transponder",
    component: () => import("../views/ReceiveTransponder.vue"),
    beforeEnter: authGuard,
    meta: { title: "Receive Transponder" },
  },
  {
    path: "/view-received-transponder/:id",
    name: "View Received Transponder",
    component: () => import("../views/ProcessPreOrder.vue"),
    beforeEnter: authGuard,
    meta: {
      title: "View Received Transponder",
    },
  },
  {
    path: "/view-fulfilment/:id",
    name: "View Fulfilment",
    component: () => import("../views/ProcessFulfilment.vue"),
    beforeEnter: authGuard,
    meta: {
      title: "View Fulfilment",
    },
  },

  {
    path: "/profile",
    name: "My Profile",
    component: () => import("../views/Profile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Profile" },
  },

  {
    path: "/search",
    name: "Search Transponder",
    component: () => import("../views/SearchTransponder.vue"),
  },
  {
    path: "/ready-for-shipping/:id",
    name: "Ready for Shipping",
    component: () => import("../views/ReadyForShipping.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/account-type/:id",
    name: "AccountType",
    component: () => import("../views/AccountTypes.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/view-assigned-transponder/:id",
    name: "View Assigned Transponder",
    component: () => import("../views/ViewAssignments.vue"),
    beforeEnter: authGuard,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authGuard);

export default router;
