<template>
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <a
      class="sidebar-brand d-flex align-items-center justify-content-center"
      href="/dashboard"
      style="background-color: white"
    >
      <!-- <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas fa-laugh-wink"></i>
      </div> -->
      <img
        src="../../assets/favicon.png"
        style="height: 35px; width: 160px"
        alt="LOGO"
      />
      <!-- <span class="ms-1 font-weight-bold">Innovative Toll</span> -->
      <!-- <div class="sidebar-brand-text mx-3">Innovative Toll</div> -->
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <li class="nav-item pl-1">
      <!-- <a class="nav-link" href="/dashboard"> -->
      <router-link class="nav-link" to="/dashboard"
        ><i class="fas fa-chart-pie"></i> <span>DASHBOARD</span></router-link
      >
    </li>
    <li class="nav-item pl-1">
      <router-link class="nav-link" to="/pre-order"
        ><i class="fas fa-calendar-alt"></i>
        <span>RECEIVE ORDERS</span></router-link
      >
    </li>
    <li class="nav-item pl-1">
      <router-link class="nav-link" to="/warehouse"
        ><i class="fas fa-warehouse"></i> <span>WAREHOUSE</span></router-link
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapse"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-shopping-cart"></i>
        <span>OPEN ORDERS</span>
      </a>
      <div
        id="collapse"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">CLIENT REQUESTS</h6>
          <router-link class="collapse-item" to="/client-open-orders"
            >All CLIENT ORDERS</router-link
          >
          <router-link class="collapse-item" to="/my-client-open-orders"
            >MY CLIENT ORDERS</router-link
          >
          <h6 class="collapse-header">CARRIER REQUESTS</h6>
          <router-link class="collapse-item" to="/carrier-open-orders"
            >All CARRIER ORDERS</router-link
          >
          <router-link class="collapse-item" to="/my-carrier-open-orders"
            >MY CARRIER ORDERS</router-link
          >
          <h6 class="collapse-header">Admin:</h6>
          <router-link class="collapse-item" to="/open-order-assignments"
            >ORDER ASSIGNMENTS</router-link
          >
        </div>
      </div>
    </li>
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapse3"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-shipping-fast"></i>
        <span>SHIPPING</span>
      </a>
      <div
        id="collapse3"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">CLIENT SHIPPING</h6>
          <router-link class="collapse-item" to="/my-ready-for-shipping-orders"
            >READY FOR SHIPPING</router-link
          >
          <router-link class="collapse-item" to="/shipped-transponders"
            >SHIPPED</router-link
          >
        </div>
      </div>
    </li>
    <hr class="sidebar-divider d-none d-md-block" />
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapse2"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-plus"></i>
        <span>ADDONS</span>
      </a>
      <div
        id="collapse2"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">TIMS Addons</h6>
          <router-link class="collapse-item" to="/clients">
            CLIENTS
          </router-link>
          <router-link class="collapse-item" to="/location">
            LOCATIONS
          </router-link>
          <router-link class="collapse-item" to="/agencies">
            AGENCIES
          </router-link>
          <router-link class="collapse-item" to="/client-accounts">
            AGENCY ACCOUNTS
          </router-link>
          <router-link class="collapse-item" to="/threshold">
            THRESHOLD
          </router-link>
          <router-link class="collapse-item" to="/transponder-type">
            TRANSPONDER TYPE
          </router-link>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      role: "",
      // username: "",
    };
  },
  methods: {
    goBack() {
      // Navigate back in the browser history
      window.history.back();
      window.location.reload();
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.username = this.currentUser.username;
    this.role = localStorage.getItem("role");
    this.isAdmin = this.role === "Supervisor";
  },
};
</script>
