<template>
  <div id="wrapper">
    <SideBar />
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <Topbar />
        <!-- Begin Page Content -->
        <div class="container-fluid px-5 mt-5">
          <!-- Page Heading -->
          <div
            class="d-sm-flex align-items-center justify-content-between mb-2"
          >
            <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
          </div>
          <div class="text-muted mb-3">Transponder Statistics</div>

          <div class="row">
            <div class="col-xl-12 col-md-12 mb-4">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    Transponder by status
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-6 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div
                                class="text-xs font-weight-bold text-info text-uppercase mb-1"
                              >
                                Active
                              </div>
                              <div
                                class="h5 mb-0 font-weight-bold text-gray-800"
                              >
                                <!-- {{ this.onlineTransactionsInsights.count }} -->
                                {{
                                  getTransponderDashBoardInsights?.activeTransponders
                                }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-bus-alt fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div
                                class="text-xs font-weight-bold text-info text-uppercase mb-1"
                              >
                                Deactivated
                              </div>
                              <div
                                class="h5 mb-0 font-weight-bold text-gray-800"
                              >
                                {{
                                  getTransponderDashBoardInsights?.deactivatedTransponders
                                }}
                                <!-- {{ this.onlineTransactionsInsights.withinSLA }} -->
                              </div>
                            </div>
                            <div class="col-auto">
                              <i
                                class="fas fa-exclamation-triangle fa-2x text-gray-300"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-md-12 mb-4">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    Transponder Orders
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-4 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div
                                class="text-xs font-weight-bold text-info text-uppercase mb-1"
                              >
                                New
                              </div>
                              <div
                                class="h5 mb-0 font-weight-bold text-gray-800"
                              >
                                <!-- {{ this.onlineTransactionsInsights.count }} -->
                                {{
                                  getTransponderDashBoardInsights?.newFulfillMent
                                }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-archive fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div
                                class="text-xs font-weight-bold text-info text-uppercase mb-1"
                              >
                                In-progress
                              </div>
                              <div
                                class="h5 mb-0 font-weight-bold text-gray-800"
                              >
                                {{
                                  getTransponderDashBoardInsights?.inProgressFulfillment
                                }}
                                <!-- {{ this.onlineTransactionsInsights.withinSLA }} -->
                              </div>
                            </div>
                            <div class="col-auto">
                              <i
                                class="fas fa-box-open fa-2x text-gray-300"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div
                                class="text-xs font-weight-bold text-info text-uppercase mb-1"
                              >
                                Completed
                              </div>
                              <div
                                class="h5 mb-0 font-weight-bold text-gray-800"
                              >
                                {{
                                  getTransponderDashBoardInsights?.completedFulfillment
                                }}
                                <!-- {{ this.onlineTransactionsInsights.withinSLA }} -->
                              </div>
                            </div>
                            <div class="col-auto">
                              <i
                                class="fas fa-truck-loading fa-2x text-gray-300"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Area Chart -->
                    <div class="col-xl-12 col-lg-12">
                      <div class="card shadow mb-4">
                        <!-- Card Header - Dropdown -->
                        <div
                          class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
                        >
                          <h6 class="m-0 font-weight-bold text-primary">
                            Client Against Threshold
                          </h6>
                        </div>
                        <!-- Card Body -->
                        <div class="card-body">
                          <div class="chart-area">
                            <canvas id="myAreaChart"></canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </div>
      </div>
      <!-- End of Main Content -->
      <Footer />
      <!-- End of Content Wrapper -->
    </div>
  </div>
  <!-- End of Page Wrapper -->
</template>
<script>
import Topbar from "@/components/common/Topbar.vue";
import Footer from "@/components/common/Footer.vue";
import SideBar from "@/components/common/Navbar.vue";
import "datatables.net-dt/js/dataTables.dataTables";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.css";
import "@/assets/vendor/datatables/jquery.dataTables.min.js";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";

import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { useQuery } from "@vue/apollo-composable";
import pdfMake from "pdfmake/build/pdfmake";
import { computed } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { ALL_TRANSPONDER_DASHBOARD_QUERY } from "@/graphql";

export default {
  name: "Dashboard",
  components: {
    Topbar,
    Footer,
    SideBar,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {};
  },
  apollo: {
    //clients
    getTransponderDashBoardInsights: {
      query: ALL_TRANSPONDER_DASHBOARD_QUERY,
    },
  },

  methods: {},

  async created() {},
};
</script>
