<template>
  <main class="main-content">
    <section class="">

      <body class="bg-gradient min-vh-100">
        <div class="container">
          <div class="row-x">
            <div
              class="col-xl-6 col-lg-6 col-md-6 d-lg-flex d-none h-100 my-auto pe-2 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div class="card-x o-hidden border-0 shadow-lg mx-auto my-auto">
                <div class="card-body p-0">
                  <div class="d-flex justify-content-center align-items-center pt-5">
                    <img class="text-center" style="height: 70px; width: 260px" :src="require('@/assets/favicon.png')"
                      alt="LOGO" />
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="p-5">
                        <div class="text-center">
                          <h1 class="h4 text-gray-900 mb-4">
                            Transponder Inventory Management System (TIMS)
                          </h1>
                        </div>
                        <Form class="user" @submit="handleLogin" :validation-schema="schema" role="form">
                          <div class="mb-3">
                            <Field name="email" type="email" class="form-control form-control-lg"
                              placeholder="Email Address" v-model="email" />
                            <ErrorMessage name="email" class="text-danger py-3 text-sm" />
                          </div>
                          <div class="mb-3">
                            <Field name="password" type="password" class="form-control form-control-lg"
                              placeholder="Password" v-model="password" />

                            <ErrorMessage name="password" class="text-danger py-3 text-sm" />
                          </div>
                          <div class="text-center">
                            <div class="form-group py-2">
                              <div v-if="message" class="alert alert-danger text-white" role="alert">
                                {{ message }}
                              </div>
                            </div>
                            <button class="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0" :disabled="loading">
                              <span>Sign In</span>
                              <span v-show="loading" class="spinner-border text-white spinner-border-sm ml-2"></span>
                            </button>
                          </div>
                        </Form>
                        <div class="text-center">
                          <a class="small" href="/forget">Forgot Password?</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
              <div
                class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-size: cover; background-position: center"
                :style="`background-image: url(${require('@/assets/bg.jpg')})`">
                <span class="mask bg-gradient-primary opacity-1"></span>
                <h4 class="mt-5 text-white font-weight-bolder position-relative">
                  "Innovative Toll"
                </h4>
                <p class="text-white position-relative">
                  Form a relationship with a great toll management solution
                  provider and set yourself for success in the changing Tolling
                  space.
                </p>
              </div>
            </div>
            <!-- Mobile Responsive Version -->
            <div class="container-fluid h-100">
              <div class="row h-100 justify-content-center align-items-center">
                <div class="col-12 d-lg-none my-auto mx-auto">
                  <div class="card o-hidden border-0 shadow-lg mx-auto w-100">
                    <div class="card-body p-0">
                      <div class="d-flex justify-content-center align-items-center pt-5 px-4">
                        <img class="text-center" style="height: 50px; width: 180px"
                          :src="require('@/assets/favicon.png')" alt="LOGO" />
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="p-4">
                            <div class="text-center">
                              <h1 class="h4 text-gray-900 mb-4">
                                Ticket and Project Management System
                              </h1>
                            </div>
                            <Form class="user" @submit="handleLogin" :validation-schema="schema" role="form">
                              <div class="mb-3">
                                <Field name="email" type="email" class="form-control form-control-user"
                                  placeholder="Email Address" v-model="email" />
                                <ErrorMessage name="email" class="text-danger py-3 text-sm" />
                              </div>
                              <div class="mb-3">
                                <Field name="password" type="password" class="form-control form-control-user"
                                  placeholder="Password" v-model="password" />
                                <ErrorMessage name="password" class="text-danger py-3 text-sm" />
                              </div>
                              <div class="text-center">
                                <div class="form-group py-2">
                                  <div v-if="message" class="alert alert-danger text-white" role="alert">
                                    {{ message }}
                                  </div>
                                </div>
                                <button class="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0" :disabled="loading">
                                  <span>Sign In</span>
                                  <span v-show="loading"
                                    class="spinner-border text-white spinner-border-sm ml-2"></span>
                                </button>
                              </div>
                            </Form>
                            <div class="text-center">
                              <a class="small" href="/forget">Forgot Password?</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </section>
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import Cookies from "js-cookie";
import { LOGIN_USER_MUTATION } from "@/graphql";
import TokenService from "@/services/token.service.js";

import * as yup from "yup";
export default {
  name: "Login",
  components: { Form, Field, ErrorMessage },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string("Email is required!")
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string("Password is required!")
        .required("Password is required!"),
    });
    return {
      loading: false,
      message: "",
      schema,
    };
  },
  methods: {
    async handleLogin(user) {
      this.message = "";
      await this.$apollo
        .mutate({
          mutation: LOGIN_USER_MUTATION,
          variables: {
            username: user.email,
            password: user.password,
          },
        })
        .then(async (response) => {
          if (response.data.loginEmployee.authData.token) {
            localStorage.setItem(
              "token",
              response.data.loginEmployee.authData.token
            );
            TokenService.setUser(response.data.loginEmployee.employee);
            localStorage.setItem(
              "role",
              response.data.loginEmployee.user.role[0].group_id.group_name
            );
            this.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          this.message = error.message;
        });
    },
  },
};
</script>
<style scoped src="@/assets/css/argon-dashboard.css">
.bg_image {
  background-image: url(https://images.pexels.com/photos/357514/pexels-photo-357514.jpeg?auto=compress&cs=tinysrgb&w=1600g);
  background-size: cover;
  background-position: center;
}
</style>
